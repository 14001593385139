#title {
  flex: 1;
  font-weight: bold;
  font-size: 200%;
  text-align: center;
  margin-bottom: auto;
  margin-top: auto;
}
.header a {
  color: inherit;
  text-decoration: none;
}

#headerLinks {
  flex: 1;
  display: flex;
  justify-content: space-evenly;
}
#headerLinks > a {
  display: flex;
  flex: 1;
  justify-content: space-evenly;
  margin-right: 10px;
  margin-left: 10px;
}
.headerIcon {
  color: rgba(0, 0, 0);
  height: auto;
  width: 100%;
  max-width: 50px;
  margin-top: 10px;
}
.headerIcon:hover {
  color: rgba(0, 0, 0, 0.5);
}
.tooltips {
  font-size: 0.75em !important;
  padding: 2px 10px !important;
}
