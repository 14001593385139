.spotify {
  width: 100%;
  position: absolute;
  bottom: 10px;
}

.spotifyPics {
  display: flex;
}

.spotifyPics div {
  flex: 1;
  position: relative;
}

.spotifyPics div img {
  position: relative;
  pointer-events: none;
}
.spotifyPics div svg {
  position: absolute;
  top: 12px;
  left: 21px;
  pointer-events: none;
}

.spotifyPics div p {
  pointer-events: none;
}
