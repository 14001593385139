.App {
  text-align: center;
  width: 100%;
  color: rgba(0, 0, 0);
}

.App-body {
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.textNoCursor {
  cursor: default;
}

.sidebarPointer {
  animation: pointer-move infinite 700ms alternate;
}

.constructionIcon {
  animation: construct infinite 2s alternate;
}

@keyframes pointer-move {
  from {
    transform: translate(10px, 10px);
  }
  to {
    transform: translateY(-10px, -10px);
  }
}

@keyframes construct {
  from {
    transform: scale(1.2);
  }
  to {
    transform: scale(0.8);
  }
}

@media only screen and (min-width: 600px) {
  .App {
  }

  .App-body {
    flex-direction: row;
    padding-top: 15vh;
    align-items: flex-start;
    box-sizing: border-box;
    justify-content: space-evenly;
  }
}

.construction {
  flex-direction: column;
  align-items: center;
}

button {
  border: 1px solid black;
  color: rgba(0, 0, 0);
  background: transparent;
}
button:hover {
  background: black;
  color: rgb(255, 255, 255);
  transition: 0.4s;
}
select {
  border: 1px solid black;
  color: rgba(0, 0, 0);
  background: transparent;
}
select:hover {
  background: black;
  color: rgb(255, 255, 255);
  transition: 0.4s;
}

.buttonArea {
  margin: auto;
}
